import { getFileDetail } from 'Api/fileDetails';
import { useQuery } from 'react-query';

export const useGetFileDetail = (userToken, query, enableFetching) => {
  const { isLoading, data, isError } = useQuery(
    ['GetFileDetail', query, userToken],
    getFileDetail,
    {
      staleTime: Infinity,
      enabled: enableFetching,
    },
  );
  return { isLoading, data, isError };
};
