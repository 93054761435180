import { Table } from 'flowbite-react';
import PropTypes from 'prop-types';
import { statusFile } from 'utils/constants';

const DataTable = ({ data, columns, onRowClicked }) => {

    const getBgColorStatus = status => {
        // eslint-disable-next-line default-case
        switch (status) {
            case statusFile.READY:
                return 'bg-green-100 text-green-800'
            case statusFile.NOT_READY:
                return 'bg-gray-100 text-gray-800'
            case statusFile.PROCESSED:
                return 'bg-purple-100 text-purple-800'
            case statusFile.ERROR:
                return 'bg-red-100 text-red-800'
        }
    };
  return (
    <Table striped={true} data-testid='list'>
      <Table.Head>
        {columns.map((item, key) => (
          <Table.HeadCell key={key} className="bg-gray-100 text-gray-900 text-xs uppercase font-semibold rounded-none">{item.name}</Table.HeadCell>
        ))}
      </Table.Head>
      <Table.Body className="divide-y table-search-result text-sm text-gray-500">
        {data?.map((item, key) => {
          return (
            <Table.Row
              key={key}
              className={`data-table-row bg-white dark:border-gray-700 dark:bg-gray-900 ${
                !item.isEmpty ? 'cursor-pointer' : ''
              }`}
              data-row-meta={JSON.stringify(item)}
              onClick={onRowClicked ? () => onRowClicked(item) : null}
            >
              {columns.map((col, index) => {
                return (
                  <Table.Cell key={index} data-testid="test-table-row">
                    {col.isStatus ? (
                      <>
                        <span
                          className={
                            `${ getBgColorStatus(item.status) } text-sm capitalize font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900`}
                        >
                          {item[col.selector]}
                        </span>
                        <span>{item.file}</span>
                      </>
                    ) : (
                      item[col.selector]
                    )}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fileNumber: PropTypes.string,
      address: PropTypes.string,
      file: PropTypes.string,
      date: PropTypes.string,
      isCompleted: PropTypes.bool,
      partnerName: PropTypes.string,
      rating: PropTypes.number,
      baseScore: PropTypes.number,
      created_date: PropTypes.string,
      score: PropTypes.number,
      totalScore: PropTypes.number,
      percent: PropTypes.string,
    }),
  ),
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      selector: PropTypes.oneOf([
        'address',
        'date',
        'statusTitle',
        'file',
        'fileNumber',
        'partnerCompanyName',
        'rating',
        'scoreAdjustment',
        'createdDate',
        'bmaScore',
        'totalScore',
        'percent',
      ]).isRequired,
      isStatus: PropTypes.bool.isRequired,
      isRating: PropTypes.bool,
    }),
  ).isRequired,
  onRowClicked: PropTypes.func,
};

DataTable.defaultProps = {
  data: [
    {
      fileNumber: '1234',
      address: 'Street Addr, City, ST 9999',
      file: '3 BMA Documents, 2 Verified',
      date: '06/07/2022 10:30am',
      isCompleted: false,
    },
    {
      fileNumber: '1234',
      address: 'Street Addr, City, ST 9999',
      file: '3 BMA Documents, 2 Verified',
      date: '06/07/2022 10:30am',
      isCompleted: false,
    },
    {
      fileNumber: '1234',
      address: 'Street Addr, City, ST 9999',
      file: '3 BMA Documents, 2 Verified',
      date: '06/07/2022 10:30am',
      isCompleted: true,
    },
    {
      fileNumber: '1234',
      address: 'Street Addr, City, ST 9999',
      file: '3 BMA Documents, 2 Verified',
      date: '06/07/2022 10:30am',
      isCompleted: true,
    },
    {
      fileNumber: '1234',
      address: 'Street Addr, City, ST 9999',
      file: '3 BMA Documents, 2 Verified',
      date: '06/07/2022 10:30am',
      isCompleted: true,
    },
    {
      fileNumber: '1234',
      address: 'Street Addr, City, ST 9999',
      file: '3 BMA Documents, 2 Verified',
      date: '06/07/2022 10:30am',
      isCompleted: true,
    },
  ],
};

export default DataTable;
