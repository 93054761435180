import { LoginPage, FileListPage, FileDetailPage } from 'pages';
import { routePaths } from 'utils/constants';

const routes = [
  {
    path: routePaths.LOGIN_PAGE,
    component: <LoginPage />,
    isGuarded: false,
    children: [],
  },
  {
    path: routePaths.FILE_LIST_PAGE,
    component: <FileListPage />,
    isGuarded: true,
    children: [],
  },
  {
    path: routePaths.FILE_DETAIL_PAGE,
    component: <FileDetailPage />,
    isGuarded: false,
    children: [],
  }
];

export default routes;
