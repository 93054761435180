import { withAuthenticationRequired } from '@auth0/auth0-react';
import { MainLayout } from 'components/layouts';
import { TextInput } from 'flowbite-react';
import 'global.css';
import { SearchIcon } from 'components/assets';
import { useState, useEffect, useMemo } from 'react';
import FileListPageContent from 'components/pages/FileList/fileListPageContent';
import debouce from 'lodash.debounce';
import useUser from 'hooks/useUser';
import { setCookie } from '../utils/cookie';
import { useGetCurrentUser } from 'hooks';

const FileList = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [enableFetching, setEnableFetching] = useState(false);
  const { userToken } = useUser();

  const handleChange = e => {
    if (e.key === 'Enter') {
      setSearchQuery(e.target.value);
    }
  };
  const handleClick = e => {
    const text = document.getElementById('base');
    setSearchQuery(text.value);
  };
  const debouncedResults = useMemo(() => {
    return debouce(handleChange, 700);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    if (userToken && userToken.length > 0) {
      setEnableFetching(true);
    }
  }, [userToken]);

  const { data } = useGetCurrentUser(userToken, enableFetching);

  useEffect(() => {
    if (data && data.data.id) {
      setCookie('userId', data.data.id);
    }
  }, [data]);
  return (
    <MainLayout>
      <div className="container mx-auto px-3 md:pt-8 relative z-10 flex flex-col items-center w-full ">
        <>
        <div className="relative mb-[15px] md:mb-10 flex justify-center w-full md:w-2/3 lg:w-1/2">
          <TextInput
            data-testid="test-search-input"
            //rightIcon={() => <SearchIcon onclick={handleClick} />}
            id="base"
            type="text"
            sizing="lg"
            placeholder="Search by File Id or Address or Name"
            onKeyDown={debouncedResults}
            autoFocus
            className="search-input w-full"
          />
          <div className="absolute right-5 top-6 cursor-pointer" onClick={handleClick}><SearchIcon /></div>
          </div>
        </>
        <FileListPageContent searchQuery={searchQuery} />
      </div>
    </MainLayout>
  );
};
export default withAuthenticationRequired(FileList);
