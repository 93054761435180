import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const listFiles = ({ queryKey }) => {
  return execute(
    httpMethods.GET,
    apiEndoints.LIST_FILES,
    { authorization: queryKey[3] },
    {
      ...(queryKey[2] !== '' && { input: queryKey[2] }),
    },
    10,
  );
};

listFiles.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { listFiles };
