import { execute } from 'utils/API';
import { apiEndoints, httpMethods } from 'utils/constants';
import PropTypes from 'prop-types';

const getFileDetail = ({ queryKey }) => {
  return execute(
    httpMethods.GET,
    apiEndoints.FILE_DETAIL,
    { authorization: queryKey[2] },
    {
      ...(queryKey[1] !== '' && { fileId: queryKey[1] }),
    },
    10,
  );
};

getFileDetail.propTypes = {
  queryKey: PropTypes.array.isRequired,
};

export { getFileDetail };
