import React from "react";
import Navbar from 'components/primitives/navbar';
import { PropTypes } from 'prop-types';
import Pattern from 'assets/images/pattern.svg';

const MainLayout = ({ children, hasNavbar = true }) => {
  return (
    <div className="w-full bg-gray-50 h-screen" data-testid="test-main-layout">
      {hasNavbar && <Navbar />}
      <>{children}</>
      <img className="pattern" src={Pattern} alt="Pattern" />
    </div>
  );
};
MainLayout.propTypes = {
  hasNavbar: PropTypes.bool,
  children: PropTypes.any,
};

export default MainLayout;
