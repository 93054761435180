import DataTable from 'components/DataTable';
import useUser from 'hooks/useUser';
import { PropTypes } from 'prop-types';
import { useEffect, useState } from 'react';
import { formatDateNotTime } from 'utils/utils';
import { pageData, urlRouter, statusFile } from 'utils/constants';
import { useGetListFiles } from 'hooks';
import { useNavigatePage } from 'hooks/useNavigatePage';
import { EmptySearchIcon } from 'components/assets';

const FileListPageContent = ({ searchQuery }) => {
  const { handleNavigate } = useNavigatePage(urlRouter.FILE_DETAIL_PAGE);
  const [currentPage, setCurrentPage] = useState(1);
  const [enableFetching, setEnableFetching] = useState(false);
  const { userToken } = useUser();

  const handleStatus = status => {
    // eslint-disable-next-line default-case
    switch (status) {
      case statusFile.OPEN:
        return statusFile.OPEN;
      case statusFile.SAVED_REVIEW_SHEET:
        return statusFile.REPORT_SAVED;
      case statusFile.CLOSED:
        return statusFile.CLOSED;
      case statusFile.READY:
        return statusFile.READY;
      case statusFile.NOT_READY:
        return statusFile.NOT_READY_SHOW;
      case statusFile.PROCESSED:
        return statusFile.PROCESSED;
      case statusFile.ERROR:
        return statusFile.ERROR;
    }
  };

  useEffect(() => {
    userToken && setEnableFetching(true);
  }, [userToken]);

  const [fileEntries, setFileEntries] = useState([]);
  const { isLoading, isFetching, data, isError } = useGetListFiles(
    currentPage,
    searchQuery,
    userToken,
    enableFetching,
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  useEffect(() => {
    const fileEntriesTemp = data
      ? data.data.map(entity => {
          return {
            id: entity.id,
            fileId: entity.fileId,
            address: entity.propertyAddress,
            statusTitle: handleStatus(entity.status),
            openDate: formatDateNotTime(entity.openDate),
            closeDate: formatDateNotTime(entity.closeDate),
            status: entity.status,
          };
        })
      : [];
      setFileEntries(fileEntriesTemp);
  }, [data]);

  if (isLoading || isFetching) {
    return (
      <span data-testid="test-loader" className="w-full flex justify-center">
        Files Loading...
      </span>
    );
  }

  if (isError) {
    return (
      <span data-testid="test-error" className="w-full flex justify-center">
        An Error occured When fetching the data
      </span>
    );
  }

  return (
    <>
      <div className="overflow-x-auto w-full mb-8 block bg-white rounded-lg drop-shadow">
        {
        fileEntries.length > 0 
        ? 
        <DataTable
          columns={pageData.FILE_LIST.TABLE_COLUMNS_DATA}
          data={fileEntries}
          onRowClicked={handleNavigate}
        /> 
        : 
        searchQuery.length > 0 
        ?
        <div data-testid="test-loader" className="w-full flex flex-col justify-center items-center py-[100px] text-gray-500 shadow-none">
          <EmptySearchIcon/>
          <span className="mt-[30px] mb-[10px] font-bold text-xl">Result Not Found</span>
          <span className="text-base">Whoops ... this information is not available for a moment</span>
        </div>
        :
        <div data-testid="test-loader" className="w-full flex justify-center py-[100px] text-gray-500 shadow-none text-base">
          Please enter a search term to load files.
        </div>
        }
      </div>
    </>
  );
};

FileListPageContent.propTypes = {
  searchQuery: PropTypes.string.isRequired,
};

export default FileListPageContent;
