import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone'; // import timezone plugin
import utc from 'dayjs/plugin/utc'; // import UTC plugin
dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = date => {
  const formattedDate = dayjs(date).format('MM/DD/YYYY hh:mmA'); // Simplifies date formatting
  return formattedDate;
};

const formatDateNotTime = date => {
  if (date === null) {
    return '';
  }
  const formattedDate = dayjs(date).format('MMM DD, YYYY'); // Use built-in formatting
  return formattedDate;
};

const formatDateAndTime = date => {
  if (date === null) {
    return '';
  }
  // Get the user's local timezone using dayjs
  const userTimeZone = dayjs.tz.guess();

  const dateTime = dayjs(date).tz(userTimeZone);
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short'
  }).format(dateTime);

  return formattedDate;
};

export { formatDate, formatDateNotTime, formatDateAndTime };
