const httpMethods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  DELETE: 'delete',
};

const userTypes = {
  USER: 'USER',
  MASTER_USER: 'MASTER_USER',
};

const apiEndoints = {
  LIST_FILES: 'Accounting/Search',
  FILE_DETAIL: 'Accounting/Details',
  USER: 'User',
};

const routePaths = {
  FILE_LIST_PAGE: '/',
  FILE_REVIEW_PAGE: '/review/:id',
  FILE_DETAIL_PAGE: '/details',
  LOGIN_PAGE: '/login',
  PARTNERS: '/partners',
};

const pageLabels = {
  LOGIN: {},
  FILE_LIST: {
    TABLE_FILE_ID_TITLE: 'File Id',
    TABLE_FILE_NUMBER_TITLE: 'File Number',
    TABLE_ADDRESS_TITLE: 'Property',
    TABLE_STATUS_TITLE: 'Status',
    TABLE_DATE_OPEN_TITLE: 'Open Date',
    TABLE_DATE_CLOSE_TITLE: 'Close Date',
    TABLE_DATE_CREATED_TITLE: 'Date created',
  },
  FILE_REVIEW: {},
  PARTNERS: {
    TABLE_PARTNER_NAME: 'Partner',
    TABLE_PARTNER_RATING: 'Rating',
    TABLE_PARTNER_BASE_CORE: 'Base Score',
  },
  PARTNER_DETAIL: {
    TABLE_BMA: 'BMA',
    TABLE_BMA_SCORE: 'BMA Score',
    TABLE_TOTAL_SCORE: 'Total Score',
    TABLE_PERCENT: 'Percent',
  },
};

const pageData = {
  LOGIN: {},
  FILE_LIST: {
    TABLE_COLUMNS_DATA: [
      {
        name: pageLabels.FILE_LIST.TABLE_FILE_ID_TITLE,
        selector: 'fileId',
        isStatus: false,
        isRating: false,
      },
      {
        name: pageLabels.FILE_LIST.TABLE_ADDRESS_TITLE,
        selector: 'address',
        isStatus: false,
        isRating: false,
      },
      {
        name: pageLabels.FILE_LIST.TABLE_DATE_OPEN_TITLE,
        selector: 'openDate',
        isStatus: false,
        isRating: false,
      },
      {
        name: pageLabels.FILE_LIST.TABLE_DATE_CLOSE_TITLE,
        selector: 'closeDate',
        isStatus: false,
        isRating: false,
      },
      {
        name: pageLabels.FILE_LIST.TABLE_STATUS_TITLE,
        selector: 'statusTitle',
        isStatus: true,
        isRating: false,
      }
    ],
  },
  FILE_REVIEW: {},
  PARTNERS: {
    TABLE_COLUMNS_DATA: [
      {
        name: pageLabels.PARTNERS.TABLE_PARTNER_NAME,
        selector: 'partnerCompanyName',
        isStatus: false,
        isRating: false,
      },
      {
        name: pageLabels.PARTNERS.TABLE_PARTNER_RATING,
        selector: 'rating',
        isStatus: false,
        isRating: true,
      },
      {
        name: pageLabels.PARTNERS.TABLE_PARTNER_BASE_CORE,
        selector: 'scoreAdjustment',
        isStatus: false,
        isRating: false,
      },
    ],
  },
  PARTNER_DOCUMENTS: {
    TABLE_COLUMNS_DATA: [
      {
        name: pageLabels.PARTNER_DETAIL.TABLE_BMA,
        selector: 'createdDate',
        isStatus: false,
        isRating: false,
      },
      {
        name: pageLabels.PARTNER_DETAIL.TABLE_BMA_SCORE,
        selector: 'bmaScore',
        isStatus: false,
        isRating: false,
      },
      {
        name: pageLabels.PARTNER_DETAIL.TABLE_TOTAL_SCORE,
        selector: 'totalScore',
        isStatus: false,
        isRating: false,
      },
      {
        name: pageLabels.PARTNER_DETAIL.TABLE_PERCENT,
        selector: 'percent',
        isStatus: false,
        isRating: false,
      },
    ],
  },
};

const componentLabels = {
  NAVBAR: {
    REPORT_LABEL: 'View Reports',
    LOGOUT_LABEL: 'Logout',
    PARTNERS: 'Partners',
  },
};

const urlRouter = {
  FILE_REVIEW_PAGE: '/review',
  FILE_DETAIL_PAGE: '/details',
  PARTNERS: '/partners',
};

const statusFile = {
  OPEN: 'Open',
  SAVED_REVIEW_SHEET: 'SavedReviewSheet',
  CLOSED: 'Closed',
  REPORT_SAVED: 'REPORT SAVED',
  READY: 'Ready',
  NOT_READY: 'NotReady',
  NOT_READY_SHOW: 'Not Ready',
  PROCESSED: 'Processed',
  ERROR: 'Error'
}
export {
  httpMethods,
  apiEndoints,
  userTypes,
  routePaths,
  pageLabels,
  pageData,
  componentLabels,
  urlRouter,
  statusFile
};
