import React from 'react';
import {withAuthenticationRequired} from "@auth0/auth0-react";
import './style.scss';

const LineItem = ({leftText, rightText, icon, className, classRightText}) => {
    return (
        <div className={`custom-color flex items-center text-sm w-full p-[15px] ${className && className}`}>
            <div className="w-[40%] text-gray-500">{leftText}</div>
            <div className="flex items-center w-[60%]">
                {icon && <div className="mr-[10px]">{icon}</div>}
                <div className={`text-gray-900 ${classRightText && classRightText}`}>{rightText}</div>
            </div>
        </div>
    );
};
export default withAuthenticationRequired(LineItem);
